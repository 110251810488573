import { useStores } from "@hooks/use-stores"
import { CategoryByNumber } from "@modules/Essay/store"
import { isValid } from "@utils/date"
import { normalizePath } from "@utils/path"
import { getWatermarkURL } from "@utils/path"
import { capitalizeTheFirstLetterOfEachWord } from "@utils/strings"
import { Button, Divider } from "antd"
import cx from "classnames"
import dayjs from "dayjs"
import { Link } from "gatsby"
import React, { useEffect, useState } from "react"

import styles from "../style.module.less"

const Header = ({
  id,
  name,
  category,
  photographer,
  photographerId,
  photographerNickname,
  model,
  modelNickname,
  publishedIn,
  handleChangeTab,
  isProfile,
  urlEssayCover,
}) => {
  const { authSite, post } = useStores()
  const { user } = authSite
  const { getCloudinaryWatermarkLink } = post

  const [imageWatermarkUrl, setImageWatermarkUrl] = useState(null)

  const pageUri = normalizePath(`/ensaios/${name}`)
  const pagePhotographer = normalizePath(`/perfil/${photographerId}`)

  const srcImage = getWatermarkURL(urlEssayCover, user)

  const getCloudinaryImageURL = async () => {
    const imageLink = await getCloudinaryWatermarkLink(srcImage)
    setImageWatermarkUrl(imageLink)
  }

  useEffect(() => {
    getCloudinaryImageURL()
  }, [urlEssayCover])

  return !isProfile ? (
    <>
      <div
        className="card__preview"
        style={{
          backgroundImage: `url("${process.env.S3_IMAGES}/${urlEssayCover}")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          // height: "360px",
        }}
      >
        <div className="desc">
          <div className="live">{CategoryByNumber[category]}</div>
          <div className="card__title h6">{name}</div>
          <div className="card__author">
            <div className="card__author__block">
              <span>Fotos </span>
              <Link
                to={`${pagePhotographer}?searchId=${photographerId}`}
                style={{ color: "white" }}
              >
                <div className="photogr_name">
                  {capitalizeTheFirstLetterOfEachWord(
                    (!photographerNickname
                      ? photographer
                      : photographerNickname) ||
                      (!modelNickname ? model : modelNickname)
                  )}
                </div>
              </Link>
            </div>
            <div>{dayjs(publishedIn).format("DD.MM.YYYY")}</div>
          </div>
        </div>
        {/* <img class="card__pic" src={srcImage} /> */}
      </div>

      {/* <Link
        to={`${pageUri}?searchId=${id}`}
        className={cx("mb8 f20 fw7 truncate db mt16", styles.linksHeader)}
        title={name}
      >
        <span className={styles.essayName}> {name}</span>
      </Link> */}

      {/* <div className="flex flex-row items-center">
        <Button
          className={styles.buttonCategory}
          onClick={() =>
            handleChangeTab(CategoryByNumber[category].toLowerCase())
          }
        >
          <span className="f12 b comet ttu">{CategoryByNumber[category]}</span>
        </Button>

        <Divider
          type="vertical"
          className={cx("header-divider bg-french-rose mh4", styles.divider)}
        />

        <span
          className="f12 normal flex-grow-1 comet truncate db"
          title={capitalizeTheFirstLetterOfEachWord(photographer || model)}
        >
          <Link
            to={`${pagePhotographer}?searchId=${photographerId}`}
            className={styles.linksHeader}
          >
            <span>
              {capitalizeTheFirstLetterOfEachWord(photographer || model)}
            </span>
          </Link>
        </span>

        {isValid(publishedIn) && (
          <span className={cx("f12 flex-end normal comet")}>
            {dayjs(publishedIn).format("DD/MM/YYYY")}
          </span>
        )}
      </div> */}
    </>
  ) : (
    <div
      className="card__preview"
      style={{
        backgroundImage: `url("${process.env.S3_IMAGES}/${urlEssayCover}")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        // height: "422px",
      }}
    >
      <div className="desc">
        <div className="live">{CategoryByNumber[category]}</div>
        <div className="card__title h6">{name}</div>
        <div className="card__author">
          <div className="card__author__block">
            <span>Fotos </span>
            <Link
              to={`${pagePhotographer}?searchId=${photographerId}`}
              style={{ color: "white" }}
            >
              <div className="photogr_name">
                {capitalizeTheFirstLetterOfEachWord(
                  (!photographerNickname
                    ? photographer
                    : photographerNickname) ||
                    (!modelNickname ? model : modelNickname)
                )}
              </div>
            </Link>
          </div>
          <div>{dayjs(publishedIn).format("DD.MM.YYYY")}</div>
        </div>
      </div>
      {/* <img class="card__pic" src={srcImage} /> */}
    </div>
  )
}

export default Header
