import { useStores } from "@hooks/use-stores"
import { normalizePath } from "@utils/path"
import { getWatermarkURL } from "@utils/path"
import { capitalizeTheFirstLetterOfEachWord } from "@utils/strings"
import { Avatar } from "antd"
import cx from "classnames"
import { Link } from "gatsby"
import React from "react"
import * as Icon from "react-feather"

// import { Icon } from "@components/Icons"
import styles from "../style.module.less"

const FooterSession = ({
  modelUrlProfile,
  model,
  modelNickname,
  modelUsername,
  likes,
  comments,
}) => {
  const pagePhotographer = normalizePath(`/perfil/${modelUsername}`)
  const { authSite } = useStores()
  const { user } = authSite
  return (
    <>
      <div className="card__body">
        <div className="card__user">
          <Link to={pagePhotographer} style={{ color: "#808191" }}>
            <div className="card__ava">
              <img
                className="card__pic"
                src={`${process.env.S3_IMAGES}/${modelUrlProfile}`}
                alt=""
              />
              <div className="card__man">
                {capitalizeTheFirstLetterOfEachWord(
                  !modelNickname ? model : modelNickname
                )}
              </div>
            </div>
          </Link>
          <div className="card__desc">
            <div className="card__game">
              <div className="mv8 flex flex-row items-center">
                <img
                  src={require("@images/icons/heart-full-color.svg")}
                  width="20px"
                  height="20px"
                  alt="Curtidas"
                />
                <span className="ml4 mr8 subtextColorLikes">{likes}</span>

                <img
                  src={require("@images/icons/comment-full.svg")}
                  width="20px"
                  height="20px"
                  alt="Curtidas"
                />
                <span className="ml4 subtextColorComments">{comments}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FooterSession
