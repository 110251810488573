import { useStores } from "@hooks/use-stores"
import { normalizePath } from "@utils/path"
import { Col } from "antd"
import _map from "lodash/map"
import React, { useEffect, useState } from "react"
import Mansory, { ResponsiveMasonry } from "react-responsive-masonry"
import { useMedia } from "react-use-media"

import Card from "./components/Card"
import Header from "./components/Header"
import styles from "./style.module.less"

const MostLikedEssays = () => {
  const {
    essay: { getMostLiked },
  } = useStores()
  const [essays, setEssays] = useState([])

  const isDesktop = useMedia({ minWidth: 1025 })
  const isMobile = useMedia({ maxWidth: 600 })

  useEffect(() => {
    async function fetchData() {
      const { data } = await getMostLiked()
      setEssays(data)
    }
    fetchData()
  }, [])
  const renderCards = () => {
    let cards = [1, 2, 3]
    return cards.map((card) => <Card key={card} essays={essays} index={card} />)
  }

  const renderCardsMansory = () => {
    let cardsMobile = [1, 2, 3, 4]
    return (
      <div className={styles.cards__mobile__container}>
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 320: 1, 474: 1, 750: 2, 1023: 2 }}
        >
          <Mansory gutter={24} className="mansoryClass">
            {_map(cardsMobile, (index) => {
              return <Card key={index} essays={essays} index={index} />
            })}
          </Mansory>
        </ResponsiveMasonry>
      </div>
    )
  }

  return essays.length ? (
    <>
      <Col span={24} className={styles.mostLiked__title}>
        <span>Mais curtidos</span>
      </Col>
      <Col xl={12} lg={12} md={24} sm={24} className={styles.responsiveCol}>
        <Header
          isHeaderMobile={isMobile}
          essay={essays[0]}
          href={`${normalizePath(`/ensaios/${essays[0].name}`)}?searchId=${
            essays[0].id
          }`}
        />
      </Col>
      {isDesktop ? (
        <Col xl={12} lg={12} md={12} sm={24} className={`styles.responsiveCol`}>
          {renderCards()}
        </Col>
      ) : (
        renderCardsMansory()
      )}
    </>
  ) : null
}

export default MostLikedEssays
