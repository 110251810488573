import CategoryEssay from "@components/CategoryEssay"
import { useStores } from "@hooks/use-stores"
import { CategoryByNumber } from "@modules/Essay/store"
import { isValid } from "@utils/date"
import { normalizePath } from "@utils/path"
import { getWatermarkURL } from "@utils/path"
import { capitalizeTheFirstLetterOfEachWord } from "@utils/strings"
import { Button, Divider, Col, Row } from "antd"
import cx from "classnames"
import dayjs from "dayjs"
import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { useMedia } from "react-use-media"

import styles from "../style.module.less"

const Header = ({ essay, noDesc, href, isHeaderMobile }) => {
  const { authSite, post } = useStores()
  const { user } = authSite
  const { getCloudinaryWatermarkLink } = post
  const isDesktop = useMedia({ minWidth: 1025 })
  const isMobile = useMedia({ maxWidth: 600 })

  const [imageWatermarkUrl, setImageWatermarkUrl] = useState(null)

  const srcImage = getWatermarkURL(essay.urlEssayCover, user)

  const getCloudinaryImageURL = async () => {
    const imageLink = await getCloudinaryWatermarkLink(srcImage)
    setImageWatermarkUrl(imageLink)
  }

  useEffect(() => {
    getCloudinaryImageURL()
  }, [])

  const renderDescriptionHeader = () => (
    <div className={styles.header}>
      <CategoryEssay categoryName={CategoryByNumber[essay.category]} />

      <div className={styles.header__name}>{essay.name}</div>
      <div className={styles.header__heartRow}>
        <img
          src={require(isHeaderMobile
            ? "@images/icons/heart-full-color.svg"
            : "@images/icons/heart-full-light.svg")}
          width={isHeaderMobile ? "25.33px" : "17.25px"}
          height={isHeaderMobile ? "17.25px" : "15.33"}
          alt="Curtidas"
          className={styles.heart}
        />
        <span>{essay.likes}</span>
      </div>

      <Button href={href} className={styles.header__seeEssayButton}>
        Ver ensaio
      </Button>
    </div>
  )

  return essay ? (
    isHeaderMobile ? (
      <Row href={href} className={styles.header__card}>
        <Col
          xl={12}
          lg={24}
          md={24}
          sm={24}
          className={styles.card__container__image}
        >
          <div
            className={styles.header__image}
            style={{
              backgroundImage: `url("${process.env.S3_IMAGES}/${essay.urlEssayCover}")`,
              backgroundSize: "cover",
              backgroundPosition: "top",
              height: noDesc
                ? isDesktop
                  ? "204px"
                  : "230px"
                : isDesktop
                ? "694px"
                : isMobile
                ? "230px"
                : "667px",
              borderRadius: "25px",
            }}
          />
        </Col>
        <Col xl={12} lg={12} md={24} sm={24} className={styles.header__col}>
          {renderDescriptionHeader()}
        </Col>
      </Row>
    ) : (
      <>
        {!noDesc ? (
          <div className={styles.header__image__wrapper}>
            <div className={styles.header__container__image}>
              <div
                className={styles.header__image}
                style={{
                  backgroundImage: `url("${process.env.S3_IMAGES}/${essay.urlEssayCover}")`,
                  backgroundSize: "cover",
                  backgroundPosition: "top",
                  height: noDesc
                    ? isDesktop
                      ? "204px"
                      : "230px"
                    : isDesktop
                    ? "694px"
                    : isMobile
                    ? "230px"
                    : "667px",
                  borderRadius: "25px",
                  with: noDesc ? "" : isDesktop ? "551px" : "",
                }}
              ></div>
            </div>
            {renderDescriptionHeader()}
          </div>
        ) : (
          <div
            className={styles.header__image}
            style={{
              backgroundImage: `url("${process.env.S3_IMAGES}/${essay.urlEssayCover}")`,
              backgroundSize: "cover",
              backgroundPosition: "top",
              height: noDesc
                ? isDesktop
                  ? "204px"
                  : "230px"
                : isDesktop
                ? "694px"
                : isMobile
                ? "230px"
                : "667px",
              borderRadius: "25px",
              with: noDesc ? "" : isDesktop ? "551px" : "",
            }}
          />
        )}
      </>
    )
  ) : null
}

export default Header
