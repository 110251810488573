// import SessionCard from "@components/SessionCard"
import MostLikedEssays from "@components/MostLikedEssays"
import SessionCard from "@components/SessionCardAnonimatta"
import { useStores } from "@hooks/use-stores"
import EmptyEssay from "@modules/Essay/Site/components/EmptyEssay"
import MostFollowedProfiles from "@modules/Essay/Site/components/MostFollowedProfiles"
import { Row } from "antd"
import _get from "lodash/get"
import _map from "lodash/map"
import React, { useEffect, useState } from "react"
import Mansory, { ResponsiveMasonry } from "react-responsive-masonry"
import { useMedia } from "react-use-media"

import { getWindow } from "../../../../utils/getWindow"

const ListEssay = ({ data, handleChangeTab, isProfile }) => {
  const [mostFollowedProfiles, setMostFollowedProfiles] = useState([])

  const { feed } = useStores()
  const { getMostFollowed } = feed

  const isDesktop = useMedia({ minWidth: 1025 })
  const isTablet = useMedia({ maxWidth: 768 })
  const isMobile = useMedia({ maxWidth: 200 })

  const handleGetMostFollowed = async () => {
    const { user } = JSON.parse(getWindow().localStorage.getItem("authSite"))
    const idProfileLogged = _get(user, "id", "")
    const response = await getMostFollowed(idProfileLogged)

    setMostFollowedProfiles(
      response.mostFollowed.map((item) => ({
        id: item.id,
        urlProfile: item.urlProfile,
        name: item.name,
        location: `${item.city}, ${item.state}`,
        followers: item.followersCnt,
      }))
    )
  }

  useEffect(() => {
    handleGetMostFollowed()
  }, [])

  if (data && !data.length) {
    return (
      <div className="mb48">
        <EmptyEssay isEssay={true} />
      </div>
    )
  }

  const renderMobile = () => {
    const dueSlice = isMobile ? 1 : 8
    return data ? (
      <>
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 320: 1, 474: 1, 750: 2, 1023: 2 }}
        >
          <Mansory gutter={32} className="mansoryClass">
            {_map(data?.slice(0, dueSlice), (item, index) => {
              return (
                <SessionCard
                  key={index}
                  handleChangeTab={handleChangeTab}
                  {...item}
                  isProfile={isProfile}
                />
              )
            })}
          </Mansory>
        </ResponsiveMasonry>
        <MostLikedEssays />
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 320: 1, 474: 1, 750: 2, 1023: 2 }}
        >
          <Mansory gutter={32} className="mansoryClass">
            {_map(data, (item, index) => {
              return index <= dueSlice ? null : (
                <SessionCard
                  key={index}
                  handleChangeTab={handleChangeTab}
                  {...item}
                  isProfile={isProfile}
                />
              )
            })}
          </Mansory>
        </ResponsiveMasonry>
      </>
    ) : null
  }

  return (
    <>
      {data && !isDesktop && renderMobile()}
      {isDesktop && (
        <Row gutter={["24", "36"]}>
          {_map(data, (item, index) => {
            return index === 7 ? (
              <>
                <SessionCard
                  key={index}
                  handleChangeTab={handleChangeTab}
                  {...item}
                  isProfile={isProfile}
                />
                <MostLikedEssays />
              </>
            ) : (
              <SessionCard
                key={index}
                handleChangeTab={handleChangeTab}
                {...item}
                isProfile={isProfile}
              />
            )
          })}
        </Row>
      )}
    </>
  )
}

export default ListEssay
