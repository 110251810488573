import CategoryEssay from "@components/CategoryEssay"
import { CategoryByNumber } from "@modules/Essay/store"
import { normalizePath } from "@utils/path"
import { Button, Col, Row } from "antd"
import React from "react"
import { useMedia } from "react-use-media"

import styles from "../style.module.less"
import Header from "./Header"

const Card = ({ essays, index }) => {
  const isDesktop = useMedia({ minWidth: 1025 })
  return (
    <Row
      href={`${normalizePath(`/ensaios/${essays[index].name}`)}?searchId=${
        essays[index].id
      }`}
      className={styles.card}
    >
      <Col
        xl={12}
        lg={isDesktop ? 12 : 24}
        md={24}
        sm={24}
        className={styles.card__container__image}
      >
        <Header essay={essays[index]} noDesc={true} />
      </Col>
      <Col xl={12} lg={12} md={24} sm={24} className={styles.card__col}>
        <CategoryEssay
          categoryName={CategoryByNumber[essays[index].category]}
        />
        <div className={styles.card__name}>{essays[index].name}</div>
        <div className={styles.card__heartRow}>
          <img
            src={require("@images/icons/heart-full-color.svg")}
            width="25.33px"
            height="17.25px"
            alt="Curtidas"
            className={styles.heart}
          />
          <span>{essays[index].likes}</span>
        </div>

        <Button
          href={`${normalizePath(`/ensaios/${essays[index].name}`)}?searchId=${
            essays[index].id
          }`}
          className={styles.card__seeEssayButton}
        >
          Ver ensaio
        </Button>
      </Col>
    </Row>
  )
}
export default Card
