import { normalizePath } from "@utils/path"
import { Divider } from "antd"
import { Col, Row } from "antd"
import { Link } from "gatsby"
import React from "react"

import cx from "classnames"

import FooterSession from "./components/FooterSession"
import Header from "./components/Header"
import styles from "./style.module.less"

const SessionCard = (props) => {
  const pageUri = normalizePath(`/ensaios/${props.name}`)

  return (
    <Col
      xl={6}
      lg={12}
      md={12}
      sm={24}
      className={cx("card__ensaio", styles.responsiveCol)}
    >
      <Link to={`${pageUri}?searchId=${props.id}`} className="card">
        <Header {...props} />
        <FooterSession {...props} />
      </Link>
    </Col>
  )
}

export default SessionCard
